import { graphql } from "gatsby"
import React from "react"
import * as styles from "./simple-hero.module.scss"

interface SimpleHeroProps {
  banner: any
  tag?: string
  category?: string
}

export const SimpleHero = ({ banner, tag, category }: SimpleHeroProps) => {
  const { heading, subheading } = banner?.elements || {
    heading: { value: "no heading" },
    subheading: { value: "" },
  }
  return (
    <section
      className={`container ${styles.bannerContent}`}
      id="simple-hero-override"
    >
      {tag || category ? (
        <h1>{tag ? tag : `Articles tagged with ${category}`}</h1>
      ) : (
        <>
          <h1>{heading.value}</h1>
          {subheading?.value.length > 0 && (
            <p className={styles.subheading}>{subheading.value}</p>
          )}
        </>
      )}
    </section>
  )
}

export const query = graphql`
  fragment SimpleHeroBannerFragment on kontent_item_simple_hero_banner {
    __typename
    id
    elements {
      heading {
        value
      }
      subheading {
        value
      }
    }
  }
`
